import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-content rounded" }
const _hoisted_3 = {
  key: 0,
  class: "fw-bolder"
}
const _hoisted_4 = { class: "svg-icon svg-icon-1" }
const _hoisted_5 = { class: "modal-body scroll-y px-10 px-lg-15 pt-0 pb-15" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.targetId,
    tabindex: "-1",
    "aria-hidden": "true",
    "data-bs-backdrop": "static"
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`modal-dialog modal-dialog-centered mw-${_ctx.width}px`)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["modal-header pb-0 border-0", {'justify-content-end': _ctx.title==''}])
        }, [
          (_ctx.title != '')
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-modal'))),
            class: "btn btn-sm btn-icon btn-active-color-primary",
            "data-bs-dismiss": "modal"
          }, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "header"),
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ], 2)
  ], 8, _hoisted_1))
}