
import { defineComponent } from "vue"
export default defineComponent({
    props: {
        targetId: {
            type: String
            , required: true
        }
        , width: {
            type: Number
            , default: 700
        }
        , title: {
            type: String
            , required: false
            , default: ''
        }
    }
})
